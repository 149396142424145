import {
  CLOUD_RECORDINGS_FREQUENCIES,
  CLOUD_RECORDINGS_STATUSES,
  CLOUD_RECORDINGS_STORAGE_DURATION,
  CAMERA_STATUSES_LIST,
  REGIONS,
} from "@/components/constants.js"
import { CameraFeatureFlag } from "@evercam/shared/types/camera"
import vendorModels from "@evercam/shared/constants/vendorModels"

const nvrModels = vendorModels.NVRMODELS

const location = [
  { name: "All", value: "" },
  { name: "Has location", value: "true" },
  { name: "No location", value: "false" },
]

const boolItems = [
  { name: "All", value: "" },
  { name: "Yes", value: "true" },
  { name: "No", value: "false" },
]

const crStatuses = [{ name: "All", value: "" }, ...CLOUD_RECORDINGS_STATUSES]

const durations = [
  { name: "All", value: "" },
  ...CLOUD_RECORDINGS_STORAGE_DURATION,
]

const frequencies = [
  { value: "", name: "All" },
  ...CLOUD_RECORDINGS_FREQUENCIES,
]

const statuses = [{ name: "All", value: "" }, ...CAMERA_STATUSES_LIST]

const FEATURE_FLAGS = [
  { name: "Edge video", value: CameraFeatureFlag.EdgeVideo },
  { name: "Bim Compare", value: CameraFeatureFlag.BimCompare },
  { name: "Gate Report", value: CameraFeatureFlag.GateReport },
  { name: "Stream HLS", value: CameraFeatureFlag.VideoStream },
  {
    name: "WebRTC",
    value: CameraFeatureFlag.WebRTC,
  },
  { name: "ANPR", value: CameraFeatureFlag.ANPR },
  { name: "PTZ", value: CameraFeatureFlag.PTZ },
  {
    name: "Copilot Timelapse Reports",
    value: CameraFeatureFlag.CopilotTimelapseReport,
  },
  {
    name: "Object Detection",
    value: CameraFeatureFlag.ObjectDetection,
  },
  {
    name: "Segmentation",
    value: CameraFeatureFlag.Segmentation,
  },
  {
    name: "Segmentation Auto-Labelling",
    value: CameraFeatureFlag.SegmentationAutoLabelling,
  },
]

export default (self) => {
  const vendorModels = self?.vendorModels ? self.vendorModels : []

  return {
    name: {
      component: "TextFieldSearchFilter",
      name: "cameraName",
    },
    exid: {
      component: "TextFieldSearchFilter",
      name: "cameraExid",
    },
    userFullname: {
      component: "TextFieldSearchFilter",
      name: "cameraOwner",
    },
    userEmail: {
      component: "TextFieldSearchFilter",
    },
    projectName: {
      component: "TextFieldSearchFilter",
    },
    cameraHost: {
      component: "TextFieldSearchFilter",
      name: "cameraIp",
    },
    deviceId: {
      component: "TextFieldSearchFilter",
    },
    cameraHttpPort: {
      component: "TextFieldSearchFilter",
      listeners: {
        keypress: (e) => self.allowKeypress(e, { number: true }),
      },
    },
    nvrHttpPort: {
      component: "TextFieldSearchFilter",
      listeners: {
        keypress: (e) => self.allowKeypress(e, { number: true }),
      },
    },
    nvrModel: {
      component: "SelectSearchFilter",
      attributes: {
        items: [{ value: "", name: "All" }, ...nvrModels],
      },
    },
    nvrRtspPort: {
      component: "TextFieldSearchFilter",
      listeners: {
        keypress: (e) => self.allowKeypress(e, { number: true }),
      },
    },
    username: {
      component: "TextFieldSearchFilter",
    },
    password: {
      component: "TextFieldSearchFilter",
    },
    cameraModel: {
      component: "SelectSearchFilter",
      attributes: {
        items: [{ value: "", name: "All" }, ...vendorModels],
      },
    },
    timezone: {
      component: "TextFieldSearchFilter",
    },
    region: {
      component: "SelectSearchFilter",
      attributes: {
        items: [{ name: "All", value: "" }, ...REGIONS],
      },
    },
    recordingFromNvr: {
      component: "SelectSearchFilter",
      attributes: {
        items: boolItems,
      },
    },
    isPublic: {
      component: "SelectSearchFilter",
      attributes: {
        items: boolItems,
      },
    },
    status: {
      component: "SelectSearchFilter",
      attributes: {
        items: statuses,
      },
    },
    cloudRecordingStorageDuration: {
      component: "SelectSearchFilter",
      attributes: {
        items: durations,
      },
    },
    cloudRecordingStatus: {
      component: "SelectSearchFilter",
      attributes: {
        items: crStatuses,
      },
    },
    cloudRecordingFrequency: {
      component: "SelectSearchFilter",
      attributes: {
        items: frequencies,
      },
    },
    location: {
      name: "hasLocation",
      component: "SelectSearchFilter",
      attributes: {
        items: location,
      },
    },
    featureFlags: {
      component: "SelectSearchFilter",
      attributes: {
        items: FEATURE_FLAGS,
        multiple: true,
      },
    },
  }
}
